@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Inter-Light.woff2?v=3.13") format("woff2"),
    url("../fonts/Inter-Light.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2?v=3.13") format("woff2"),
    url("../fonts/Inter-Regular.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-Medium.woff2?v=3.13") format("woff2"),
    url("../fonts/Inter-Medium.woff?v=3.13") format("woff");
}

body {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
}

html {
  font-size: 10px;
}

html,
body {
  /* background-color: #1F1F1F; */
  width: 100%;
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #40a9ff;
}

a:active {
  color: #096dd9;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: scroll;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: scroll;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::-moz-selection {
  color: #fff;
  background-color: #1890ff;
}

::selection {
  color: #fff;
  background-color: #1890ff;
}

/* scrollbar */

.custom-scrollbar {
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 9px, black 9px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 9px, black 9px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.custom-scrollbar:hover {
  mask-position: left top;
  -webkit-mask-position: left top;
}

*::-webkit-scrollbar {
  height: 0px;
  width: 9px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: rgba(84, 84, 84, 1.0);
  border-radius: 4px;
  border: 2px solid transparent;
}

*::-webkit-scrollbar-button {
  display: none;
}

.upload-list {
  overflow: scroll;
  padding-left: 0;
  padding-right: 0;
}

/* .upload-list::-webkit-scrollbar {
  width: 7px;
}

.upload-list::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.4);
  border-radius: 10rem;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
}

.upload-list::-webkit-scrollbar-track-piece:start {
  background-color: transparent;
}

.upload-list::-webkit-scrollbar-track-piece:end {
  background-color: transparent
} */

.main-map~div>.overlays {
  pointer-events: none;
}

.mapboxgl-control-container {
  display: none;
}

.mapboxgl-ctrl-group {
  background-color: #333333 !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.mapboxgl-ctrl-group button+button {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFF'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E") !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFF'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFF'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%235C5C5C'/%3E%3C/svg%3E") !important;
}

.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left {
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
  transition-duration: 400ms;
  transition-property: bottom;
}

#wrapper>div:not(.mapboxgl-compare) {
  position: absolute !important;
}

.footer-is-open .mapboxgl-ctrl-bottom-right,
.footer-is-open .mapboxgl-ctrl-bottom-left {
  bottom: 66px;
}

.footer-is-open.expanded .mapboxgl-ctrl-bottom-right,
.footer-is-open .mapboxgl-ctrl-bottom-left {
  bottom: 235px;
}

.hidden {
  visibility: hidden;
}

.react-map-gl {
  transition: width ease;
}

.mapboxgl-compare {
  background-color: rgba(31, 31, 31, 0.56);
  position: absolute;
  width: 4px;
  height: 100%;
  z-index: 1;
}

.mapboxgl-compare .compare-swiper-vertical {
  background-color: #1F1F1F;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(2px);
  border-radius: 4px;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: -12px;
  margin: -12px 1px 0;
  color: #fff;
  cursor: ew-resize;
  display: flex;
  justify-content: center;
  font-size: 8px;
  align-items: center;
  font-weight: 700;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  min-height: 24px !important;
  margin: 8px !important;
}

/* 2x8 branco 6px radius centralizado */

/* Keppler.GL RangeSlider */

div.close-button.close-bottom {
  display: none;
}

.kgl-light .kg-range-slider__handle,
.kgl-dark .kg-range-slider__handle {
  border-width: 0px;
  height: 10px;
  width: 10px;
}

.kgl-light .kg-slider .kg-range-slider {
  background-color: #E2E2E2;
}

.kgl-light .kg-range-slider__input,
.kgl-light .kg-range-slider__input:focus {
  background-color: #EEEEEE;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #757575;
}

.kgl-light .kg-range-slider__handle,
.kgl-light .kg-range-slider__handle:hover {
  background-color: #333333;
}

.kgl-light .kg-range-slider__bar {
  background-color: #1F1F1F;
}

.kgl-dark .kg-slider .kg-range-slider {
  background-color: #333333;
}

.kgl-dark .kg-range-slider__handle,
.kgl-dark .kg-range-slider__handle:hover {
  background-color: #CBCBCB,
}

.kgl-dark .kg-range-slider__bar {
  background-color: #AFAFAF;
}

.kgl-dark .kg-range-slider__input,
.kgl-dark .kg-range-slider__input:focus {
  background-color: #1F1F1F;
  border: 1px solid rgba(255, 255, 255, 0.2);
  caret-color: #AFAFAF;
  color: #AFAFAF;
}

div[role='dialog'] div strong {
  word-break: break-all;
}

#small-logo {
  width: 42px;
  height: 40px;
  background-image: url("../images/maply.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
}
